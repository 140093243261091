



















































































































































































import { Component, Vue } from 'vue-property-decorator'
import moment from 'moment'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import { CCACurriculumController } from '@/services/request.service'
import detailModal from '@/components/DetailModal.vue'
import MessageModal from './components/messageModal.vue'
import ResultModal from './components/ResultModal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import Guide from '@/components/Guide.vue'
import { getGuideViewStatus, getIntroShow } from '@/utils/utils'

@Component({
  components: {
    detailModal,
    MessageModal,
    FlexTooltip,
    filterGroup,
    ResultModal,
    Guide,
  },
})
export default class CCAClass extends Vue {
  private filter: any = {
    name: '',
  }
  private classInfo: any = {
    title: '',
    infoList: '',
  }
  private messageInfo: any = {
    title: '',
    infoList: [],
    type: 'confirm',
    width: 658,
    loading: undefined,
  }
  private loading: boolean = false
  private refreshLoading: boolean = false
  private doubleConfirm = false
  private dPromiseRes: any = null
  private classDetailModalShow: boolean = false
  private guideVis: any = false
  private selectedRowKeys: any = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
  }
  private messageModalShow: boolean = false
  private classData: any = []
  private submitLoading: boolean = false
  private dayOfWeek: number = 1

  private get columns(): Array<Object> {
    return [
      {
        dataIndex: 'select',
        key: 'select',
        title: this.$t('CCAClass.enroll'),
        align: 'center',
        width: 80,
        scopedSlots: { customRender: 'select' },
      },
      {
        dataIndex: 'name',
        key: 'name',
        title: this.$t('CCAClass.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'classTime',
        title: this.$t('CCAClass.classTime'),
        scopedSlots: { customRender: 'classTime' },
        // width: 160,
        ellipsis: true,
      },
      {
        dataIndex: 'teacherNames',
        key: 'teacherNames',
        title: this.$t('CCAClass.teacherNames'),
        // width: 200,
        ellipsis: true,
        scopedSlots: { customRender: 'teacherNames' },
      },
      {
        dataIndex: 'classRoomName',
        key: 'classroom',
        title: this.$t('CCAClass.classroom'),
        // width: 120,
        scopedSlots: { customRender: 'classroom' },
      },
      {
        dataIndex: 'chargeFlag',
        key: 'isCharge',
        title: this.$t('CCAClass.isCharge'),
        width: 90,
        ellipsis: true,
        scopedSlots: { customRender: 'isCharge' },
      },
      {
        dataIndex: 'enrollTime',
        key: 'enrollTime',
        title: this.$t('CCAClass.enrollTime'),
        ellipsis: true,
        // width: 120,
        scopedSlots: { customRender: 'enrollTime' },
      },
      {
        dataIndex: 'status',
        key: 'status',
        title: this.$t('CCAClass.status'),
        width: 100,
        scopedSlots: { customRender: 'status' },
      },
      {
        dataIndex: 'enrolledNum',
        key: 'enrolledNum',
        title: this.$t('CCAClass.enrolledNum'),
        align: 'left',
        width: 100,
        scopedSlots: { customRender: 'enrolledNum' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        width: 110,
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }
  private get status(): Array<any> {
    return [
      {
        label: this.$t('staticCode.1001'),
        value: '1001',
      },
      {
        label: this.$t('staticCode.1021'),
        value: '1021',
      },
      {
        label: this.$t('staticCode.1022'),
        value: '1022',
      },
      {
        label: this.$t('staticCode.1023'),
        value: '1023',
      },
      {
        label: this.$t('staticCode.1024'),
        value: '1024',
      },
    ]
  }
  private get weeks(): Array<any> {
    return Array.from({ length: 7 }).map((item, index) => {
      const day = index + 1
      return {
        label: this.$tc(`weekday.short.${day}`),
        value: day,
        selectedList: this.selectedRowKeys[day] || [],
        enrollableNum:
          (this.classData.filter(item => item.dayOfWeek === day)[0] || {}).canApplyNum || 0,
      }
    })
  }
  private get classDataOfDay(): any {
    return (
      (this.classData.filter(item => item.dayOfWeek === this.dayOfWeek)[0] || {})
        .ccaCurriculumList || []
    )
  }
  private get showFooter(): any {
    for (let key in this.selectedRowKeys) {
      if ((this.selectedRowKeys[key] || []).length) return true
    }
    return false
  }
  private get ratio(): any {
    return this.$store.state.ratio
  }
  private changeName = debounce(() => {
    this.getData()
  }, 500)

  private async getData(): Promise<any> {
    if (this.loading) return
    this.loading = true
    this.classData = []
    let condition = {
      name: this.filter.name === '' ? undefined : this.filter.name.trim(),
    }
    CCACurriculumController.list(condition)
      .then(res => {
        this.classData = res.data
        this.classData.forEach(item => {
          item.ccaCurriculumList.forEach(cca => {
            let enrollTimeStart = cca.applyTimeStart
              ? moment(cca.applyTimeStart).format('MM[.]DD HH:mm')
              : ''
            let enrollTimeEnd = cca.applyTimeEnd
              ? moment(cca.applyTimeEnd).format('MM[.]DD HH:mm')
              : ''
            let timeStart = cca.timeStart ? moment(cca.timeStart).format('YYYY/MM/DD') : ''
            let timeEnd = cca.timeEnd ? moment(cca.timeEnd).format('YYYY/MM/DD') : ''
            if (!cca.classRoomName) {
              cca.classRoomName = this.$t('CCAClass.noClassroom')
            }
            cca.teacherNames = cca.teacherNames.join('、')
            this.$set(cca, 'select', false)
            this.$set(cca, 'enrollTime', `${enrollTimeStart}~${enrollTimeEnd}`)
          })
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.selectedRowKeys = {
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
          7: [],
        }
        this.loading = false
      })
  }

  private async fetchData(): Promise<any> {
    this.refreshLoading = true
    if (!this.loading) {
      await this.getData()
    }
    this.debounceRefresh()
  }

  private debounceRefresh = debounce(() => {
    this.refreshLoaded()
  }, 3000)

  private refreshLoaded(): void {
    this.refreshLoading = false
  }

  private submitConfirm(): void {
    let applyList = []
    let indexKey = 0
    for (let key in this.selectedRowKeys) {
      if ((this.selectedRowKeys[key] || []).length) {
        applyList = applyList.concat(
          this.selectedRowKeys[key].map((item, index) => {
            let timeStart = item.timeStart ? moment(item.timeStart).format('YYYY/MM/DD') : ''
            let timeEnd = item.timeEnd ? moment(item.timeEnd).format('YYYY/MM/DD') : ''
            let weekDay = item.dayOfWeeks.map(day => this.$tc(`weekday.${day}`)).join('、')
            this.$set(
              item,
              'formatClassTime',
              `${timeStart ? `${timeStart}~` : ''}${timeEnd}\n ${weekDay}`
            )
            return {
              ...item,
              rowSpan: index === 0 ? this.selectedRowKeys[key].length : 0,
              rowKey: indexKey++,
              weekday: this.$tc(`weekday.${key}`),
              order: index + 1,
            }
          })
        )
      }
    }
    if (!applyList.length) return
    this.messageModalShow = true
    this.messageInfo = {
      title: this.$t('CCAClass.confirmTitle'),
      infoList: applyList,
      type: 'confirm',
      width: 900,
    }
  }

  private async submit() {
    let applyList = [] as any
    let outList = [] as any
    let chargeKey = 0
    for (let key in this.selectedRowKeys) {
      if (!this.selectedRowKeys[key].length) continue
      let tmpList = this.selectedRowKeys[key]
        .map((item, index) => {
          if (item.type !== '1002') return undefined
          let timeStart = item.timeStart ? moment(item.timeStart).format('YYYY/MM/DD') : ''
          let timeEnd = item.timeEnd ? moment(item.timeEnd).format('YYYY/MM/DD') : ''
          let weekDay = item.dayOfWeeks.map(day => this.$tc(`weekday.${day}`)).join('、')
          this.$set(
            item,
            'formatClassTime',
            `${timeStart ? `${timeStart}~` : ''}${timeEnd}\n ${weekDay}`
          )
          return {
            ...item,
            rowSpan: 0,
            rowKey: chargeKey++,
            weekday: this.$tc(`weekday.${key}`),
            order: index + 1,
          }
        })
        .filter(course => course)
      if (tmpList.length) tmpList[0].rowSpan = tmpList.length
      outList = outList.concat(tmpList)
      applyList.push({
        dayOfWeek: key,
        courseIds: this.selectedRowKeys[key].map(item => item.courseId),
      })
    }
    // 供应商二次确认
    // if(outList.length){
    //   this.messageModalShow = true
    //   this.messageInfo = {
    //     title: this.$t('CCAClass.dConfirmTitle'),
    //     infoList: outList,
    //     type: 'dConfirm',
    //     width: 900,
    //   }
    //   const result = await new Promise(resolve => {
    //     this.dPromiseRes = resolve
    //   })
    //   this.dPromiseRes = null
    //   if(!result){
    //     this.messageModalShow = false
    //     return
    //   }
    // }
    this.submitLoading = true
    CCACurriculumController.apply({ applyList })
      .then(res => {
        let resultList = [] as any
        let indexKey = 0
        res.data.forEach(item => {
          let weekDay = this.$tc(`weekday.${item.dayOfWeek}`)
          resultList = resultList.concat(
            item.resultList.map((element, index) => {
              this.$set(element, 'timeList', element.timeList)
              return {
                ...element,
                rowSpan: index === 0 ? item.resultList.length : 0,
                rowKey: indexKey++,
                weekday: weekDay,
                reason: '',
              }
            })
          )
        })
        this.messageInfo = {
          title: this.$t('CCAClass.resultTitle'),
          infoList: resultList,
          type: 'result',
          width: 900,
        }
        this.messageModalShow = true
      })
      .finally(() => {
        this.submitLoading = false
        this.getData()
      })
  }

  private cancelEnroll(classItem): void {
    if (!classItem.enrollLoading) {
      this.$set(classItem, 'loading', true)
    }
    CCACurriculumController.cancelApply(classItem.courseId)
      .then(res => {
        this.$message.success(this.$tc('CCAClass.cancelSuccess'))
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.getData()
        setTimeout(() => {
          classItem.loading = false
        }, 100)
      })
  }

  private viewDetails(classId): void {
    this.getClassInfoData(classId)
  }

  private mounted() {
    let status = getGuideViewStatus()
    const introShow = getIntroShow() || {}
    if (!status.lifeBlock && introShow.pc) {
      this.guideVis = true
    }
  }

  private getClassInfoData(classId): void {
    this.classInfo = {
      title: '',
      infoList: '',
    }
    CCACurriculumController.detail(classId)
      .then(res => {
        this.classDetailModalShow = true
        this.classInfo.title = res.data.name
        this.classInfo.infoList = this.dealDetailData(res.data)
      })
      .catch(err => console.log(err))
  }

  private dealDetailData(data): any {
    let descriptionItem = {
        key: 'description',
        label: this.$t('CCAClass.classDescription'),
        value: '',
        visRow: 6,
      },
      schoolYearItem = {
        key: 'schoolYear',
        label: this.$t('CCAClass.schoolYear'),
        value: '',
      },
      enrollTimeItem = {
        key: 'enrollTime',
        label: this.$t('CCAClass.enrollTime'),
        value: '',
      },
      classTimeItem = {
        key: 'classTime',
        label: this.$t('CCAClass.classTime'),
        value: '',
      },
      // cycleItem = {
      //   key: 'cycle',
      //   label: this.$t('CCAClass.cycle'),
      //   value: ''
      // },
      minEnrollNumItem = {
        key: 'minEnrollNum',
        label: this.$t('CCAClass.minEnrollNum'),
        value: '',
      },
      maxEnrollNumItem = {
        key: 'maxEnrollNum',
        label: this.$t('CCAClass.maxEnrollNum'),
        value: '',
      },
      teacherNamesItem = {
        key: 'teacherNames',
        label: this.$t('CCAClass.teacherNames'),
        value: '',
      },
      classroomItem = {
        key: 'classroom',
        label: this.$t('CCAClass.classroom'),
        value: '',
      },
      isChargeItem = {
        key: 'isCharge',
        label: this.$t('CCAClass.isCharge'),
        value: '',
      },
      feeDescriptionItem = {
        key: 'feeDescription',
        label: this.$t('CCAClass.feeDescription'),
        value: '',
      },
      enrollRangeItem = {
        key: 'enrollRange',
        label: this.$t('CCAClass.enrollRange'),
        value: '',
      },
      courseTypeItem = {
        key: 'courseType',
        label: this.$t('CCAClass.courseType'),
        value: '',
      },
      isCourseItem = {
        key: 'isCourse',
        label: this.$t('CCAClass.isCourse'),
        value: '',
      },
      teachingTypeItem = {
        key: 'teachingType',
        label: this.$t('CCAClass.teachingType'),
        value: '',
      },
      creditItem = {
        key: 'credit',
        label: this.$t('CCAClass.credit'),
        value: '',
      }

    if (data) {
      descriptionItem.value = data.description
      isCourseItem.value = this.$tc(`common.${data.courseFlag || false}`)
      schoolYearItem.value = data.schoolYearName
      enrollTimeItem.value = `${moment(data.applyTimeStart).format(
        'YYYY[.]MM[.]DD HH:mm'
      )}~${moment(data.applyTimeEnd).format('YYYY[.]MM[.]DD HH:mm')}`
      let repeatString = data.repeatType ? this.$tc(`staticCode.${data.repeatType}`) : ''
      classTimeItem.value = data.timeList
        .map(item => {
          const time = Vue.filter('doubleMoment')([item.timeStart, item.timeEnd], 'YYYY.MM.DD')
          let dayOfWeeks: any = item.dayOfWeeks.map(day => {
            return this.$tc(`weekday.${day}`)
          })
          dayOfWeeks = dayOfWeeks.join('、')
          return `${time} ${dayOfWeeks} ${this.$t('staticCode.' + item.repeatType)}`
        })
        .join('、')

      teacherNamesItem.value = data.teachers
        ? data.teachers.map(item => `${item.enName} ${item.name}`).join('、')
        : ''
      classroomItem.value = data.classRoomName
      let enrollGrade = data.targetSections
        ? data.targetSections.map(item => item.name).join('、')
        : ''
      let enrollCollege = data.houseGroups ? data.houseGroups.map(item => item.name).join('、') : ''
      let gradeString = enrollGrade === '' ? '' : `${this.$t('common.grade')}：${enrollGrade}`
      let collegeString =
        enrollCollege === '' ? '' : `${this.$t('common.advisory')}：${enrollCollege}`
      enrollRangeItem.value = gradeString + (gradeString === '' ? '' : '; ') + collegeString
      minEnrollNumItem.value = data.numMin
      maxEnrollNumItem.value = data.numMax
      isChargeItem.value = data.chargeFlag ? this.$tc('CCAClass.yes') : this.$tc('CCAClass.no')
      feeDescriptionItem.value = data.feeDescription
      courseTypeItem.value =
        data.type === '1002' ? this.$tc('CCAClass.outOfSchool') : this.$tc('CCAClass.schoolCourse')
      creditItem.value = data.credits
      teachingTypeItem.value = data.kind
    }
    return [
      descriptionItem,
      schoolYearItem,
      enrollTimeItem,
      classTimeItem,
      // cycleItem,
      enrollRangeItem,
      minEnrollNumItem,
      maxEnrollNumItem,
      teacherNamesItem,
      classroomItem,
      courseTypeItem,
      isChargeItem,
      data.chargeFlag && feeDescriptionItem,
      isCourseItem,
      data.courseFlag && [teachingTypeItem, creditItem],
    ]
      .flat()
      .filter(item => item)
  }

  private disableEnroll(item): boolean {
    const { selectedRowKeys, classData } = this
    let reachLimit = false
    for (let i = 1; i <= 7; i++) {
      if (i === this.dayOfWeek) {
        if (
          selectedRowKeys[i] &&
          selectedRowKeys[i].length === 3 &&
          !selectedRowKeys[i].map(course => course.courseId).includes(item.courseId)
        ) {
          reachLimit = true
        }
      } else {
        if (
          selectedRowKeys[i] &&
          selectedRowKeys[i].length === 3 &&
          !selectedRowKeys[i].map(course => course.courseId).includes(item.courseId) &&
          classData
            .filter(item => item.dayOfWeek === i)?.[0]
            .ccaCurriculumList.map(course => course.courseId)
            .includes(item.courseId)
        ) {
          reachLimit = true
        }
      }
    }
    return reachLimit || item.status !== '1021' || item.num === item.numMax
  }

  private getTimeString(timeItem): string {
    const time = Vue.filter('doubleMoment')([timeItem.timeStart, timeItem.timeEnd], 'YYYY.MM.DD')
    let dayOfWeeks = timeItem.dayOfWeeks.map(day => {
      return this.$tc(`weekday.${day}`)
    })
    dayOfWeeks = dayOfWeeks.join('、')
    return `${time} ${dayOfWeeks}`
  }

  private selectItem(item, check, day): void {
    item.select = check
    if (check) {
      this.selectedRowKeys[day].push(item)
      this.classData.forEach(classDay => {
        const { ccaCurriculumList, dayOfWeek } = classDay
        if (!classDay.canApplyNum || dayOfWeek === day) return
        ccaCurriculumList.forEach(singleClass => {
          if (singleClass.courseId === item.courseId) {
            this.selectedRowKeys[dayOfWeek].push(singleClass)
            singleClass.select = check
          }
        })
      })
    } else {
      this.classData.forEach(classDay => {
        const { ccaCurriculumList, dayOfWeek } = classDay
        if (!classDay.canApplyNum) return
        let index = this.selectedRowKeys[dayOfWeek].findIndex(
          selectItem => selectItem.courseId === item.courseId
        )
        if (index === -1) return
        this.selectedRowKeys[dayOfWeek][index].select = check
        this.selectedRowKeys[dayOfWeek].splice(index, 1)
      })
    }
  }

  private created(): void {
    this.getData()
  }
}
