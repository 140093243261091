

































































import { Component, Vue, Prop, Emit, Model } from 'vue-property-decorator'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class ResultModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly messageInfo!: any
  @Prop({ default: false }) private readonly loading!: boolean
  @Prop({ default: () => {} }) private readonly config!: any

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }

  @Emit('confirm')
  private confirm(): boolean {
    // this.closeModal()
    return true
  }

  private get columns(): any {
    const columns = {
      confirm: [
        {
          dataIndex: 'weekday',
          width: 100,
          title: this.$t('common.weekday'),
          scopedSlots: { customRender: 'weekday' },
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {
                rowSpan: row.rowSpan,
              },
            }
            return obj
          },
        },
        {
          dataIndex: 'order',
          width: 70,
          title: this.$t('CCAClass.order'),
          scopedSlots: { customRender: 'order' },
        },
        {
          dataIndex: 'name',
          title: this.$t('router.CCAClass'),
          ellipsis: true,
          scopedSlots: { customRender: 'name' },
        },
        {
          key: 'formatClassTime',
          title: this.$t('common.time'),
          ellipsis: true,
          scopedSlots: { customRender: 'time' },
        },
        {
          dataIndex: 'teacherNames',
          title: this.$tc('common.teacher', 2),
          ellipsis: true,
          scopedSlots: { customRender: 'teacher' },
        },
        {
          dataIndex: 'chargeFlag',
          title: this.$t('CCAClass.isCharge'),
          scopedSlots: { customRender: 'isCharge' },
        },
      ],
      result: [
        {
          dataIndex: 'weekday',
          width: 100,
          title: this.$t('common.weekday'),
          scopedSlots: { customRender: 'weekday' },
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {
                rowSpan: row.rowSpan,
              },
            }
            return obj
          },
        },
        {
          dataIndex: 'courseName',
          ellipsis: true,
          title: this.$t('router.CCAClass'),
          scopedSlots: { customRender: 'name' },
        },
        {
          key: 'formatClassTime',
          ellipsis: true,
          title: this.$t('common.time'),
          scopedSlots: { customRender: 'time' },
        },
        {
          dataIndex: 'success',
          ellipsis: true,
          title: this.$t('CCAClass.result'),
          scopedSlots: { customRender: 'result' },
        },
      ],
      dConfirm: [
        {
          dataIndex: 'weekday',
          width: 100,
          title: this.$t('common.weekday'),
          scopedSlots: { customRender: 'weekday' },
          customRender: (value, row, index) => {
            const obj = {
              children: value,
              attrs: {
                rowSpan: row.rowSpan,
              },
            }
            return obj
          },
        },
        {
          dataIndex: 'name',
          title: this.$t('router.CCAClass'),
          ellipsis: true,
          scopedSlots: { customRender: 'name' },
        },
        {
          key: 'formatClassTime',
          title: this.$t('common.time'),
          ellipsis: true,
          scopedSlots: { customRender: 'time' },
        },
      ],
    }
    return columns[this.messageInfo?.type] || []
  }

  private getTimeString(timeItem): string {
    const time = Vue.filter('doubleMoment')([timeItem.timeStart, timeItem.timeEnd], 'YYYY.MM.DD')
    let dayOfWeeks = timeItem.dayOfWeeks.map(day => {
      return this.$tc(`weekday.${day}`)
    })
    dayOfWeeks = dayOfWeeks.join('、')
    return `${time} ${dayOfWeeks}`
  }
}
