





































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import ReportModule from '@/components/ReportModule.vue'
import TopicModule from './TopicModule.vue'

@Component({
  components: {
    ReportModule,
    TopicModule,
  },
})
export default class QuestPreview extends Vue {
  @Prop() private readonly data!: any
  @Prop() private readonly selectedGroup!: any
  @Prop() private readonly status!: any

  private first: any = []
  private alter: any = []
  private existCourse: any = []
  private existAlterCourse: any = []

  private get columns(): any {
    return [
      {
        dataIndex: 'type',
        title: this.$t('common.type'),
        scopedSlots: { customRender: 'type' },
      },
      {
        dataIndex: 'min',
        title: this.$t('courseSelection.minCredit'),
        scopedSlots: { customRender: 'min' },
      },
      {
        dataIndex: 'max',
        title: this.$t('courseSelection.maxCredit'),
        scopedSlots: { customRender: 'max' },
      },
    ]
  }

  private get compulsory(): any {
    const topics = this.data?.topics || []
    return topics
      .filter(topic => topic.compulsory)
      .map(topic =>
        topic.option
          .filter(course => this.first.includes(course.key))
          .map(
            course => course.credit
            // {
            //   if(!existId.includes(course.courseScheduleId)){
            //     existId.push(course.courseScheduleId)
            //     return course.credit
            //   } else {
            //     return 0
            //   }
            // }
          )
      )
      .flat()
      .reduce((total, current) => total + current, 0)
      .toFixed(2)
  }

  private get elective(): any {
    const topics = this.data?.topics || []
    return topics
      .filter(topic => !topic.compulsory)
      .map(topic =>
        topic.option.filter(course => this.first.includes(course.key)).map(course => course.credit)
      )
      .flat()
      .reduce((total, current) => total + current, 0)
      .toFixed(2)
  }

  private get topicError(): any {
    return this.data.topics?.filter(topic => topic.error)?.length
  }

  private get creditError(): any {
    const total = this.compulsory + this.elective
    return (
      this.compulsory < this.data.creditReq?.[0].min ||
      this.compulsory > this.data?.creditReq?.[0].max ||
      this.elective < this.data.creditReq?.[1].min ||
      this.elective > this.data?.creditReq?.[1].max ||
      total < this.data.creditReq?.[2].min ||
      total > this.data?.creditReq?.[2].max
    )
  }
  private errorStatus(topic): any {
    let creditError = false
    let numError = false
    const ids = this.first
    const group = topic.option.filter(item => ids.includes(item.key))
    const totalCredit = group
      .map(item => item.credit)
      // 防止 reduce 报空数组的错
      .concat([0])
      .reduce((total, current) => total + current)
    creditError =
      topic.creditMax &&
      topic.creditMin &&
      (totalCredit < topic.creditMin || totalCredit > topic.creditMax)
    numError =
      topic.numMax && topic.numMin && (group.length < topic.numMin || group.length > topic.numMax)
    return creditError || numError
  }

  private onErrorChange(params): void {
    this.$set(
      this.data?.topics.find(item => item.key === params.key),
      'error',
      params.status
    )
  }

  private saveQuest(): void {
    if (this.topicError || this.creditError) {
      this.$warning({
        title: this.$t('courseSelection.warningTips'),
        okText: this.$tc('common.confirm'),
      })
      return
    }
    let firstKeys = this.data?.topics
      .map(topic =>
        topic.option
          .filter(course => this.existCourse.includes(course.courseScheduleId))
          .map(course => course.key)
      )
      .flat()
    this.$emit('save', {
      alter: this.alter.filter(item => !firstKeys.includes(item)),
      first: this.first,
    })
    // this.closeModal()
  }

  private onCourseSelect(params): void {
    const { isAlter, list, courseList } = params
    if (!isAlter) {
      this.first = list
      this.existCourse = courseList
    } else {
      this.alter = list
    }
  }

  @Watch('selectedGroup', { deep: true })
  private onGroupChange(val): void {
    if (val && Object.keys(val).length) {
      const topics = this.data?.topics || []
      const { first, alter } = val
      const firstKeys = Object.keys(first).map(key => Number(key))
      const alterKeys = Object.keys(alter).map(key => Number(key))
      this.first = firstKeys.filter(key => first[key] !== 'student')
      this.alter = alterKeys.filter(key => alter[key] !== 'student')
      let existCourse = new Set()
      topics.forEach(topic => {
        this.$set(topic, 'error', this.errorStatus(topic))
        this.$set(
          topic,
          'option',
          topic.option.map(course => {
            if (firstKeys.includes(course.key)) existCourse.add(course.courseScheduleId)
            return {
              ...course,
              checked: {
                status: firstKeys.includes(course.key),
                type: first[course.key] || '',
                alterType: alter[course.key] || '',
              },
            }
          })
        )
      })
      this.existCourse = Array.from(existCourse)

      this.$nextTick(() => {
        ;(this.$refs.topicModule as any).initSelected(this.first, this.alter)
      })
    }
  }
}
