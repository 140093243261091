var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.topics || []).length)?_c('div',{staticClass:"topic-module"},_vm._l((_vm.topics || []),function(topic,index){return _c('div',{key:topic.key,staticClass:"single-topic"},[_c('span',{staticClass:"topic-title"},[_vm._v(_vm._s(((index + 1) + "." + (topic.title) + "[" + (topic.compulsory ? _vm.$t('courseSelection.compulsory') : _vm.$t('courseSelection.elective')) + "]")))]),_c('span',{staticClass:"extra-detail",style:({ color: _vm.errorStatus(topic) ? 'red' : '' })},[_vm._v(_vm._s(_vm.getExtraDetail(topic)))]),_c('div',{staticClass:"flex-style",staticStyle:{"white-space":"pre-line","line-height":"1.5","margin-bottom":"4px"}},[_c('span',{staticStyle:{"flex-shrink":"0"}},[_vm._v(_vm._s(_vm.$t('courseSelection.topicDes'))+"：")]),_c('span',{staticStyle:{"flex":"1","word-wrap":"break-word","hyphens":"auto"}},[_vm._v(_vm._s(topic.description))])]),_c('div',{staticClass:"checkbox-container"},_vm._l((topic.option),function(item){return _c('a-checkbox',{key:item.key,attrs:{"value":item.key,"checked":(item.checked || {}).status && (item.checked || {}).type !== 'student',"disabled":_vm.judgeDisable(item, topic, false)},on:{"change":function (e) { return _vm.onCheckChange(e.target.checked, item.key, topic, true, false); }}},[_vm._v(" "+_vm._s(((item.course) + " " + (_vm.$t('courseSelection.courseDetail', { duration: (_vm.durations.find(function (duration) { return duration.key === item.duration; }) || {}).value, credit: item.credit, }))))+" "),((item.checked || {}).type === 'teacher')?_c('span',{staticStyle:{"margin":"0 8px"}},[_vm._v("( "+_vm._s(_vm.$t('courseSelection.teacherModify'))+" )")]):((item.checked || {}).type === 'student')?_c('span',{staticStyle:{"margin":"0 8px"}},[_vm._v("( "+_vm._s(_vm.$t('courseSelection.studentSelect'))+" )")]):_vm._e(),(
            (item.checked || {}).status &&
              ((item.notPastedPrerequisites || []).length ||
                _vm.getNotSelectCourse(item.peers).length)
          )?[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"exclamation-circle"}}),((item.notPastedPrerequisites || []).length)?_c('span',{staticStyle:{"margin-right":"16px"}},[_vm._v(_vm._s(_vm.$t('courseSelection.notPassTips', { course: (item.notPastedPrerequisites || []).map(function (item) { return item.value; }), })))]):_vm._e(),(_vm.getNotSelectCourse(item.peers).length)?_c('span',[_vm._v(_vm._s(_vm.$t('courseSelection.notSelectTips', { course: _vm.getNotSelectCourse(item.peers) })))]):_vm._e()]:_vm._e()],2)}),1),(
        _vm.alterTopics[index] &&
          _vm.alterTopics[index].option.filter(function (item) { return !_vm.existCourse.includes(item.courseScheduleId); })
            .length &&
          !topic.compulsory
      )?_c('div',{staticClass:"alter-option"},[_c('span',{staticClass:"topic-title"},[_vm._v(_vm._s(_vm.$t('courseSelection.alternative')))]),_c('div',{staticClass:"checkbox-container"},_vm._l((_vm.alterTopics[index].option.filter(
            function (item) { return !_vm.existCourse.includes(item.courseScheduleId); }
          )),function(item){return _c('a-checkbox',{key:item.key,style:({
            color:
              (item.checked || {}).type === 'teacher'
                ? '#FAAD14'
                : (item.checked || {}).type === 'student'
                ? '#9254DE'
                : '',
          }),attrs:{"value":item.key,"checked":(item.checked || {}).status && (item.checked || {}).type !== 'student',"disabled":['ended', 'DNS'].includes(_vm.status)},on:{"change":function (e) { return _vm.onCheckChange(e.target.checked, item.key, _vm.alterTopics[index], false, true); }}},[_vm._v(" "+_vm._s(((item.course) + " " + (_vm.$t('courseSelection.courseDetail', { duration: (_vm.durations.find(function (duration) { return duration.key === item.duration; }) || {}).value, credit: item.credit, }))))+" "),((item.checked || {}).type === 'teacher')?_c('span',{staticStyle:{"margin":"0 8px"}},[_vm._v("( "+_vm._s(_vm.$t('courseSelection.teacherModify'))+" )")]):((item.checked || {}).type === 'student')?_c('span',{staticStyle:{"margin":"0 8px"}},[_vm._v("( "+_vm._s(_vm.$t('courseSelection.studentSelect'))+" )")]):_vm._e(),(
              (item.checked || {}).status &&
                ((item.notPastedPrerequisites || []).length ||
                  _vm.getNotSelectCourse(item.peers).length)
            )?[_c('a-icon',{staticStyle:{"margin-right":"4px"},attrs:{"type":"exclamation-circle"}}),((item.notPastedPrerequisites || []).length)?_c('span',{staticStyle:{"margin-right":"16px"}},[_vm._v(_vm._s(_vm.$t('courseSelection.notPassTips', { course: (item.notPastedPrerequisites || []).map(function (item) { return item.value; }), })))]):_vm._e(),(_vm.getNotSelectCourse(item.peers).length)?_c('span',[_vm._v(_vm._s(_vm.$t('courseSelection.notSelectTips', { course: _vm.getNotSelectCourse(item.peers) })))]):_vm._e()]:_vm._e()],2)}),1)]):_vm._e()])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }