




















import { CSQController } from '@/services/request.service'
import { Vue, Component, Prop } from 'vue-property-decorator'
import Quest from './components/Quest.vue'

@Component({
  components: {
    Quest,
  },
})
export default class CourseSelection extends Vue {
  private resultVis: any = false
  private questData: any = {}
  private selectedGroup: any = {}
  private status: any = ''
  private submitting: any = false

  private created(): any {
    this.getQuestData()
  }

  private get csqId(): any {
    return this.$route.query.csqId
  }

  private getQuestData(): void {
    if (!this.csqId) {
      this.$router.push({ name: 'main' })
      return
    }
    CSQController.getStudentCSQ(this.csqId).then(res => {
      //处理可能出现res.data为null的情况
      if (!res.data) return
      this.questData = this.formatDetail(res.data.csq)
      this.selectedGroup = {
        alter: res.data.alternative,
        first: res.data.fill,
      }
      this.resultVis = true
      this.status = res.data.status
    })
  }

  private formatDetail(data): any {
    const creditReq = [
      {
        type: 'compulsory',
        min: data.compulsoryCreditsLow,
        max: data.compulsoryCreditsHigh,
      },
      {
        type: 'elective',
        min: data.electiveCreditsLow,
        max: data.electiveCreditsHigh,
      },
      {
        type: 'totalCredit',
        min: data.totalLow,
        max: data.totalHigh,
      },
    ]
    const topics = data.selectionGroups.map(topic => ({
      numMin: topic.selectionCountLow,
      numMax: topic.selectionCountHigh,
      creditMin: topic.creditsLow,
      creditMax: topic.creditsHigh,
      title: topic.title,
      type: 'select',
      compulsory: topic.compulsory,
      option: topic.courses.map(course => ({
        ...course,
        course: course.description,
        credit: course.credits,
        duration: course.rounds,
        exclusive: course.mutex === 1,
        key: course.csqCourseId,
      })),
      mutexGroup: topic.mutexGroup.map(group => ({
        courses: group,
      })),
      key: topic.courseGroupId,
      description: topic.description,
    }))
    return {
      ...data,
      schoolYear: data.schoolYear.value,
      grade: data.section.value,
      date: Vue.filter('doubleMoment')(
        [data.selectionStart, data.selectionEnd],
        'YYYY.MM.DD HH:mm'
      ),
      creditReq: creditReq,
      topics,
    }
  }

  private saveQuest(params): any {
    this.submitting = true
    const { alter, first } = params
    const condition = {
      // schoolYearId: this.schoolYearId,
      // sectionId: this.gradeId,
      csqId: this.csqId,
      alternativeFill: alter,
      csqFill: first,
      otherCourse: [],
    }
    CSQController.saveCSQFill(condition)
      .then(res => {
        this.$message.success(this.$tc('common.saveSuccess'))
      })
      .finally(() => {
        this.submitting = false
        this.getQuestData()
      })
  }

  private submit(): any {
    ;(this.$refs.quest as any).saveQuest()
  }
}
