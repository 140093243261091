


























































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { isLegal } from '@/utils/utils'

@Component
export default class TopicModule extends Vue {
  @Prop() private readonly topics!: any
  @Prop() private readonly existCourse!: any
  @Prop() private readonly status!: any
  private checkedList: any = []
  private alterList: any = []
  private exclusive: any = {}
  private alterTopics: any = []

  private get durations(): any {
    return [
      {
        key: 1,
        value: this.$tc('courseSelection.semesters', 1, { count: 1 }),
      },
      {
        key: 2,
        value: this.$tc('courseSelection.semesters', 2, { num: 2 }),
      },
      {
        key: 3,
        value: this.$tc('courseSelection.schoolYears', 1, { count: 1 }),
      },
    ]
  }

  private getExtraDetail(topic): any {
    const creditStr: any =
      isLegal(topic.creditMax) && isLegal(topic.creditMin)
        ? this.$t('courseSelection.creditNeedTips', {
            credit: `${topic.creditMin}~${topic.creditMax}`,
          })
        : ''
    const optionStr: any =
      isLegal(topic.numMax) && isLegal(topic.numMin)
        ? this.$t('courseSelection.optionNeedTips', { option: `${topic.numMin}~${topic.numMax}` })
        : ''
    const compulsoryStr = !topic.compulsory ? this.$t('courseSelection.compulsoryTips') : ''
    return creditStr || optionStr || compulsoryStr
      ? `(${creditStr}${creditStr && optionStr ? '; ' : ''}${optionStr}${
          (creditStr || optionStr) && compulsoryStr ? '; ' : ''
        }${compulsoryStr})`
      : ''
  }

  private errorStatus(topic): any {
    let creditError = false
    let numError = false
    const ids = this.checkedList
    const group = topic.option.filter(item => ids.includes(item.key))
    const totalCredit = group
      .map(item => item.credit)
      // 防止 reduce 报空数组的错
      .concat([0])
      .reduce((total, current) => total + current)
    creditError =
      topic.creditMax &&
      topic.creditMin &&
      (totalCredit < topic.creditMin || totalCredit > topic.creditMax)
    numError =
      topic.numMax && topic.numMin && (group.length < topic.numMin || group.length > topic.numMax)
    return creditError || numError
  }

  private getNotSelectCourse(selectCourses): any {
    const realCourse = selectCourses?.filter(item => !this.checkedList.includes(item.key))
    if (!realCourse?.length) return ''
    return realCourse.map(item => item.value).join('、')
  }

  private judgeDisable(course, topic): any {
    const ids = this.checkedList
    const exclusiveKey = this.exclusive[topic.key] || []
    const group = topic.option.filter(
      item => ids.includes(item.key) && item.checked.type !== 'student'
    )
    const totalCredit =
      group
        .map(item => item.credit)
        // 防止 reduce 报空数组的错
        .concat([0])
        .reduce((total, current) => total + current) + course.credit
    return (
      ((totalCredit > (topic.creditMax || NaN) ||
        group.length >= (topic.numMax || NaN) ||
        exclusiveKey.includes(course.courseScheduleId)) &&
        !ids.includes(course.key)) ||
      ['ended', 'DNS'].includes(this.status) ||
      (this.existCourse.includes(course.courseScheduleId) && !this.checkedList.includes(course.key))
    )
  }

  private onCheckChange(checked, key, topic, isCompulsory, isAlter): void {
    const course = topic.option.find(course => course.key === key)
    this.$set(
      course,
      'checked',
      checked
        ? {
            status: true,
            type: 'all',
          }
        : {
            status: false,
            type: 'all',
          }
    )
    let existCourse = [...this.existCourse]
    if (isAlter) {
      checked
        ? this.alterList.push(key)
        : (this.alterList = this.alterList.filter(item => item !== key))
    } else {
      checked
        ? this.checkedList.push(key)
        : (this.checkedList = this.checkedList.filter(item => item !== key))
      checked
        ? (existCourse = existCourse.concat(course.courseScheduleId))
        : (existCourse = existCourse.filter(item => item !== course.courseScheduleId))
      // this.alterList = this.alterList.filter(item => item !== key)
    }
    if (!isAlter) {
      let relativeMutex: any = []
      existCourse.forEach(key => {
        relativeMutex = relativeMutex.concat(
          topic.mutexGroup
            ?.filter(mutexs => mutexs.courses.map(course => course.key)?.includes(key))
            ?.flat()
        )
      })
      relativeMutex = Array.from(
        new Set(
          relativeMutex
            .map(item => item.courses)
            .flat()
            .map(item => item.key)
        )
      )
      this.$set(this.exclusive, topic.key, relativeMutex)
      this.$emit('errorChange', { status: this.errorStatus(topic), key: topic.key })
    }

    this.$emit('change', {
      isAlter,
      list: isAlter ? this.alterList : this.checkedList,
      courseList: isAlter ? [] : existCourse,
    })
  }

  private initSelected(first, alter): void {
    this.checkedList = first
    this.alterList = alter

    this.topics?.forEach(topic => {
      let relativeMutex: any = []
      this.existCourse.forEach(key => {
        relativeMutex = relativeMutex.concat(
          topic.mutexGroup
            ?.filter(mutexs => mutexs.courses.map(course => course.key).includes(key))
            .flat()
        )
      })
      relativeMutex = Array.from(
        new Set(
          relativeMutex
            .map(item => item?.courses)
            .flat()
            .map(item => item.key)
        )
      )
      this.$set(this.exclusive, topic.key, relativeMutex)
    })
    this.alterTopics = cloneDeep(this.topics).map(topic => ({
      ...topic,
      option: topic.option.map(item => ({
        ...item,
        checked: {
          ...item.checked,
          status: this.alterList.includes(item.key),
          type: item.checked?.alterType,
        },
      })),
    }))
  }
}
