















































































import { Component, Vue, Prop, Emit, Model } from 'vue-property-decorator'

@Component
export default class ClassDetailModal extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly messageInfo!: any
  @Prop({ default: false }) private readonly loading!: boolean
  @Prop({ default: () => {} }) private readonly config!: any

  @Emit('change')
  private closeModal(): boolean {
    return false
  }

  @Emit('cancel')
  private cancel(): boolean {
    this.closeModal()
    return false
  }

  @Emit('confirm')
  private confirm(): boolean {
    // this.closeModal()
    return true
  }
}
